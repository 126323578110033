import { default as quote_45bdmQRQz7i3rHRMeta } from "/opt/buildhome/repo/pages/agents-breakdown/[name]/quote-bdm.vue?macro=true";
import { default as quote_45giverVq94AdZ5WmMeta } from "/opt/buildhome/repo/pages/agents-breakdown/[name]/quote-giver.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexoqdSlmZhy4Meta } from "/opt/buildhome/repo/pages/login/index.vue?macro=true";
import { default as microsoft_45callbackumB0cwW1NRMeta } from "/opt/buildhome/repo/pages/login/microsoft-callback.vue?macro=true";
import { default as draft_45edit1BvQOPIQOeMeta } from "/opt/buildhome/repo/pages/quote-cases/[id]/draft-edit.vue?macro=true";
import { default as edit4rKgvwvsEVMeta } from "/opt/buildhome/repo/pages/quote-cases/[id]/edit.vue?macro=true";
import { default as index4ghhBFz8CyMeta } from "/opt/buildhome/repo/pages/quote-cases/[id]/index.vue?macro=true";
import { default as call_45logsvy9tVi9mrmMeta } from "/opt/buildhome/repo/pages/quote-cases/call-logs.vue?macro=true";
import { default as call_45statusSRIi0KYm2dMeta } from "/opt/buildhome/repo/pages/quote-cases/call-status.vue?macro=true";
import { default as createXfNNisttH8Meta } from "/opt/buildhome/repo/pages/quote-cases/create.vue?macro=true";
import { default as dead_45tomorrowoo4EfwOXQHMeta } from "/opt/buildhome/repo/pages/quote-cases/dead-tomorrow.vue?macro=true";
import { default as draftsAVjO5NqnMoMeta } from "/opt/buildhome/repo/pages/quote-cases/drafts.vue?macro=true";
import { default as indexGxZjZK4i3cMeta } from "/opt/buildhome/repo/pages/quote-cases/index.vue?macro=true";
import { default as logsnSpbsjoKuMMeta } from "/opt/buildhome/repo/pages/quote-cases/logs.vue?macro=true";
import { default as quote_45monthly_45giver_45statsVd5YC6x7HaMeta } from "/opt/buildhome/repo/pages/quote-cases/quote-monthly-giver-stats.vue?macro=true";
import { default as statseEkDkH05JvMeta } from "/opt/buildhome/repo/pages/quote-cases/stats.vue?macro=true";
import { default as _91id_93YiOva5luO9Meta } from "/opt/buildhome/repo/pages/quote-leads/[id].vue?macro=true";
import { default as createNxzQFGpVWqMeta } from "/opt/buildhome/repo/pages/quote-leads/create.vue?macro=true";
import { default as presoldWBoUa9zau9Meta } from "/opt/buildhome/repo/pages/quote-leads/presold.vue?macro=true";
import { default as sold0datqUOBiCMeta } from "/opt/buildhome/repo/pages/quote-leads/sold.vue?macro=true";
import { default as completion_45target_45reportM1i4pLeVsUMeta } from "/opt/buildhome/repo/pages/reports/completion-target-report.vue?macro=true";
import { default as performance_45reportdiZ7TvVRiaMeta } from "/opt/buildhome/repo/pages/reports/performance-report.vue?macro=true";
import { default as indexxw5tNz4oK7Meta } from "/opt/buildhome/repo/pages/requisition-replies/index.vue?macro=true";
import { default as createlU1X6K5oxGMeta } from "/opt/buildhome/repo/pages/sale-memos/create.vue?macro=true";
import { default as index54mucRqekEMeta } from "/opt/buildhome/repo/pages/sale-memos/index.vue?macro=true";
import { default as editnC90iDi73RMeta } from "/opt/buildhome/repo/pages/sale-ready-pack/[id]/edit.vue?macro=true";
import { default as indexAIq6M176faMeta } from "/opt/buildhome/repo/pages/sale-ready-pack/[id]/index.vue?macro=true";
import { default as createvO6KAgHiCjMeta } from "/opt/buildhome/repo/pages/sale-ready-pack/create.vue?macro=true";
import { default as indexdEl90LfWl9Meta } from "/opt/buildhome/repo/pages/sale-ready-pack/index.vue?macro=true";
import { default as indexTJhVQ9iTswMeta } from "/opt/buildhome/repo/pages/whatsapp/index.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "agents-breakdown-name-quote-bdm",
    path: "/agents-breakdown/:name()/quote-bdm",
    component: () => import("/opt/buildhome/repo/pages/agents-breakdown/[name]/quote-bdm.vue")
  },
  {
    name: "agents-breakdown-name-quote-giver",
    path: "/agents-breakdown/:name()/quote-giver",
    component: () => import("/opt/buildhome/repo/pages/agents-breakdown/[name]/quote-giver.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexoqdSlmZhy4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/login/index.vue")
  },
  {
    name: "login-microsoft-callback",
    path: "/login/microsoft-callback",
    component: () => import("/opt/buildhome/repo/pages/login/microsoft-callback.vue")
  },
  {
    name: "quote-cases-id-draft-edit",
    path: "/quote-cases/:id()/draft-edit",
    meta: draft_45edit1BvQOPIQOeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/[id]/draft-edit.vue")
  },
  {
    name: "quote-cases-id-edit",
    path: "/quote-cases/:id()/edit",
    component: () => import("/opt/buildhome/repo/pages/quote-cases/[id]/edit.vue")
  },
  {
    name: "quote-cases-id",
    path: "/quote-cases/:id()",
    meta: index4ghhBFz8CyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/[id]/index.vue")
  },
  {
    name: "quote-cases-call-logs",
    path: "/quote-cases/call-logs",
    meta: call_45logsvy9tVi9mrmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/call-logs.vue")
  },
  {
    name: "quote-cases-call-status",
    path: "/quote-cases/call-status",
    meta: call_45statusSRIi0KYm2dMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/call-status.vue")
  },
  {
    name: "quote-cases-create",
    path: "/quote-cases/create",
    meta: createXfNNisttH8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/create.vue")
  },
  {
    name: "quote-cases-dead-tomorrow",
    path: "/quote-cases/dead-tomorrow",
    meta: dead_45tomorrowoo4EfwOXQHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/dead-tomorrow.vue")
  },
  {
    name: "quote-cases-drafts",
    path: "/quote-cases/drafts",
    meta: draftsAVjO5NqnMoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/drafts.vue")
  },
  {
    name: "quote-cases",
    path: "/quote-cases",
    meta: indexGxZjZK4i3cMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/index.vue")
  },
  {
    name: "quote-cases-logs",
    path: "/quote-cases/logs",
    meta: logsnSpbsjoKuMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/logs.vue")
  },
  {
    name: "quote-cases-quote-monthly-giver-stats",
    path: "/quote-cases/quote-monthly-giver-stats",
    component: () => import("/opt/buildhome/repo/pages/quote-cases/quote-monthly-giver-stats.vue")
  },
  {
    name: "quote-cases-stats",
    path: "/quote-cases/stats",
    meta: statseEkDkH05JvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/stats.vue")
  },
  {
    name: "quote-leads-id",
    path: "/quote-leads/:id()",
    meta: _91id_93YiOva5luO9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-leads/[id].vue")
  },
  {
    name: "quote-leads-create",
    path: "/quote-leads/create",
    meta: createNxzQFGpVWqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-leads/create.vue")
  },
  {
    name: "quote-leads-presold",
    path: "/quote-leads/presold",
    meta: presoldWBoUa9zau9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-leads/presold.vue")
  },
  {
    name: "quote-leads-sold",
    path: "/quote-leads/sold",
    meta: sold0datqUOBiCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-leads/sold.vue")
  },
  {
    name: "reports-completion-target-report",
    path: "/reports/completion-target-report",
    meta: completion_45target_45reportM1i4pLeVsUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reports/completion-target-report.vue")
  },
  {
    name: "reports-performance-report",
    path: "/reports/performance-report",
    meta: performance_45reportdiZ7TvVRiaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reports/performance-report.vue")
  },
  {
    name: "requisition-replies",
    path: "/requisition-replies",
    meta: indexxw5tNz4oK7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/requisition-replies/index.vue")
  },
  {
    name: "sale-memos-create",
    path: "/sale-memos/create",
    meta: createlU1X6K5oxGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-memos/create.vue")
  },
  {
    name: "sale-memos",
    path: "/sale-memos",
    meta: index54mucRqekEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-memos/index.vue")
  },
  {
    name: "sale-ready-pack-id-edit",
    path: "/sale-ready-pack/:id()/edit",
    meta: editnC90iDi73RMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-ready-pack/[id]/edit.vue")
  },
  {
    name: "sale-ready-pack-id",
    path: "/sale-ready-pack/:id()",
    meta: indexAIq6M176faMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-ready-pack/[id]/index.vue")
  },
  {
    name: "sale-ready-pack-create",
    path: "/sale-ready-pack/create",
    meta: createvO6KAgHiCjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-ready-pack/create.vue")
  },
  {
    name: "sale-ready-pack",
    path: "/sale-ready-pack",
    meta: indexdEl90LfWl9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-ready-pack/index.vue")
  },
  {
    name: "whatsapp",
    path: "/whatsapp",
    component: () => import("/opt/buildhome/repo/pages/whatsapp/index.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/sign-in",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/quotes",
    component: component_45stubGZr1UkTkg3
  }
]